<template>
  <div class="form" id="form">
    <div class="form__left">
      <div class="form__header" v-if="!success">
        <span  class="form__caption">
          Введите данные пассажира № {{index + 2}}
        </span>
      </div>
      <div v-if="success" class="form__success">
        <i class="pi pi-check-circle form__success-icon"/>
        <div class="form__success-label">
          Заявка успешно отправлена!
        </div>
        <div class="form__success-text">
          На вашу почту вскоре придут необходимые данные
        </div>
      </div>
      <form v-else class="form__content" @submit.prevent="submitForm(!v$.$invalid)">
        <div class="form__row">
          <div class="p-float-label form__field form__field--flex--1"
            :class="{'form__field--error':v$.data.first_name.$error}">
            <PInputText id="first_name" type="text" v-model="data.first_name" />
            <label for="first_name">Фамилия</label>
            <div v-if="v$.data.first_name.$error"
              class="form__validate p-error">
              {{v$.data.first_name.$errors[0].$message}}
            </div>
          </div>
          <div class="p-float-label form__field form__field--flex--1"
            :class="{'form__field--error':v$.data.second_name.$error}">
            <PInputText id="second_name" type="text" v-model="data.second_name" />
            <label for="second_name">Имя</label>
            <div v-if="v$.data.second_name.$error"
              class="form__validate p-error">
              {{v$.data.second_name.$errors[0].$message}}
            </div>
          </div>
          <div class="p-float-label form__field form__field--flex--1"
            :class="{'form__field--error':v$.data.third_name.$error}">
            <PInputText id="third_name" type="text" v-model="data.third_name" />
            <label for="third_name">Отчество</label>
            <div v-if="v$.data.third_name.$error"
              class="form__validate p-error">
              {{v$.data.third_name.$errors[0].$message}}
            </div>
          </div>
        </div>
        <div class="form__row">
          <mq-responsive target="small+" class="p-float-label form__field form__field--flex--1"
            :class="{'form__field--error':v$.data.birthday.$error}">
            <PCalendar id="birthday" v-model="data.birthday" :showIcon="true"
              :maxDate="now"/>
            <label for="birthday">Дата рождения</label>
            <div v-if="v$.data.birthday.$error"
              class="form__validate p-error">
              {{v$.data.birthday.$errors[0].$message}}
            </div>
          </mq-responsive>
          <mq-responsive target="mobile" class="p-float-label form__field form__field--flex--1"
            :class="{'form__field--error':v$.data.birthday.$error}">
            <PCalendar id="birthday" v-model="data.birthday" :showIcon="true" :touchUI="true"
              :maxDate="now"/>
            <label for="birthday">Дата рождения</label>
            <div v-if="v$.data.birthday.$error"
              class="form__validate p-error">
              {{v$.data.birthday.$errors[0].$message}}
            </div>
          </mq-responsive>
          <div class="p-float-label form__field"
            :class="{'form__field--error':v$.data.gender.$error}">
            <SelectButton v-model="data.gender" :options="genderOptions"
              optionLabel="label" optionValue="value"
              class="form__select-button"
            />
            <div v-if="v$.data.gender.$error"
              class="form__validate p-error">
              {{v$.data.gender.$errors[0].$message}}
            </div>
          </div>
          <div class="p-float-label form__field form__field--flex--1"
            :class="{'form__field--error':v$.data.citizenship.$error}">
            <PDropdown v-model="data.citizenship"
              class="form__dropdown"
              optionLabel="label"
              optionValue="label"
              filter
              :options="countries"
            />
            <label for="p_series">Гражданство</label>
            <div v-if="v$.data.citizenship.$error"
              class="form__validate p-error">
              {{v$.data.citizenship.$errors[0].$message}}
            </div>
          </div>
        </div>
        <div class="form__row">
          <div class="p-float-label form__field form__field--flex--1"
            :class="{'form__field--error':v$.passport.$error}">
            <InputMask :mask="passportMask" id="p_number" type="text" v-model="passport"
              unmask :autoClear="false" :key="passportKey" />
            <label for="p_number">Серия и номер {{isPassport ? 'паспорта' : 'загранпаспорта'}}</label>
            <div class="form__select-passport-type" @click="changePassport">
              <span v-if="isPassport">Использовать загранпаспорт</span>
              <span v-else>Использовать паспорт</span>
            </div>
            <div v-if="v$.passport.$error"
              class="form__validate p-error">
              {{v$.passport.$errors[0].$message}}
            </div>
          </div>
          <mq-responsive target="small+" class="p-float-label form__field form__field--flex--1"
            :class="{'form__field--error':v$.data.p_date.$error}">
            <PCalendar id="p_date" v-model="data.p_date" :showIcon="true"
              :maxDate="now" :minDate="data.birthday || null"/>
            <label for="p_date">Дата выдачи паспорта</label>
            <div v-if="v$.data.p_date.$error"
              class="form__validate p-error">
              {{v$.data.p_date.$errors[0].$message}}
            </div>
          </mq-responsive>
          <mq-responsive target="mobile" class="p-float-label form__field form__field--flex--1"
            :class="{'form__field--error':v$.data.p_date.$error}">
            <PCalendar id="p_date" v-model="data.p_date" :showIcon="true" :touchUI="true"
              :maxDate="now" :minDate="data.birthday || null"/>
            <label for="p_date">Дата выдачи паспорта</label>
            <div v-if="v$.data.p_date.$error"
              class="form__validate p-error">
              {{v$.data.p_date.$errors[0].$message}}
            </div>
          </mq-responsive>
        </div>
        <div class="form__row">
          <div class="p-float-label form__field form__field--flex--1"
            :class="{'form__field--error':v$.data.phone.$error}">
            <InputMask mask="+7 (999) 999-9999" id="phone" type="text" v-model="data.phone"
              :unmask="true" :autoClear="false" />
            <label for="phone">Телефон</label>
            <div v-if="v$.data.phone.$error"
              class="form__validate p-error">
              {{v$.data.phone.$errors[0].$message}}
            </div>
          </div>
          <div class="p-float-label form__field form__field--flex--1"
            :class="{'form__field--error':v$.data.email.$error}">
            <PInputText id="email" type="email" v-model="data.email" />
            <label for="email">Почта</label>
            <div v-if="v$.data.email.$error"
              class="form__validate p-error">
              {{v$.data.email.$errors[0].$message}}
            </div>
          </div>
          <div class="p-float-label form__field form__field--flex--1"
            :class="{'form__field--error':v$.data.promocode.$error || promocodeError}">
            <PInputText id="promocode"
              type="text"
              v-model="data.promocode"
              @input="promocodeInput"
            />
            <label for="promocode">Промокод</label>
            <div v-if="v$.data.promocode.$error || promocodeError"
              class="form__validate p-error">
              <template v-if="promocodeError">
                {{promocodeError}}
              </template>
              <template v-else>
                {{v$.data.promocode.$errors[0].$message}}
              </template>
            </div>
          </div>
        </div>
        <div class="form__row form__row--error">
          <transition name="fade">
            <div class="form__error" v-if="preloader.error">
              {{preloader.error}}
            </div>
          </transition>
        </div>
        <div class="form__devider"/>
        <div class="form__footer form__footer--flex-end">
          <PButton class="form__button button" type="submit" label="Далее"
            :loading="preloader.visible"/>
        </div>
      </form>
    </div>
    <div class="form__right">
      <PButton label="Удалить пассажира"
        v-if="!success"
        class="form__add-text"
        @click="deletePassenger"
      />
      <div class="form__devider"/>
      <div class="form__img-wrapper">
        <img class="form__img" :src="require('@/assets/img/form-stuff.png')">
      </div>
    </div>
    <div class="form__devider form__devider--mobile"/>
  </div>
</template>

<script>
import {MqResponsive} from "vue3-mq"
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators'
export default {
  name: 'FormAdd',
  components: {
    MqResponsive,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    form: Object,
    isAddConfirm: Boolean,
    countries: Array,
    genderOptions: Array,
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      passport: {
        required: helpers.withMessage('Поле является обязательным', required),
        minLength: helpers.withMessage('Введите корректное значение', minLength(this.passportLenght)),
      },
      data: {
        first_name: {
          required: helpers.withMessage('Поле является обязательным', required),
        },
        second_name: {
          required: helpers.withMessage('Поле является обязательным', required),
        },
        third_name: {
          required: helpers.withMessage('Поле является обязательным', required),
        },
        birthday: {
          required: helpers.withMessage('Поле является обязательным', required),
          checkDate: helpers.withMessage('Введите корректную дату', this.checkDate),
        },
        p_date: {
          required: helpers.withMessage('Поле является обязательным', required),
          checkDate: helpers.withMessage('Введите корректную дату', this.checkDate),
        },
        gender: {
          required: helpers.withMessage('Поле является обязательным', required),
        },
        email: {
          required: helpers.withMessage('Поле является обязательным', required),
          email: helpers.withMessage('Введите корректный email', email),
        },
        phone: {
          required: helpers.withMessage('Поле является обязательным', required),
          minLength: helpers.withMessage('Введите корректное значение', minLength(10)),
        },
        citizenship: {
          required: helpers.withMessage('Поле является обязательным', required),
        },
        promocode: {
          required: helpers.withMessage('Поле является обязательным', required),
        },
      }
    }
  },
  data(){
    return{
      isPassport: true,
      success: false,
      passportKey: 1,
      isPromocodeValid: false,
      promocodeError: '',
      data:{
        first_name: '',
        second_name: '',
        third_name: '',
        birthday: '',
        p_series: '',
        p_number: '',
        p_date: '',
        phone: '',
        email: '',
        gender: null,
        citizenship: '',
        promocode: '',
      },
      now: new Date(),
      preloader: {
        error: '',
        visible: false,
      },
    }
  },
  computed: {
    passportMask() {
      return this.isPassport ? '9999 999999' : '99 9999999'
    },
    passportLenght() {
      return this.isPassport ? 10 : 9;
    },
    passport: {
      get() {
        return this.data.p_series + this.data.p_number;
      },
      set(value) {
        if (this.isPassport) {
          this.data.p_series = value.slice(0,4);
          this.data.p_number = value.slice(4,10);
        } else {
          this.data.p_series = value.slice(0,2);
          this.data.p_number = value.slice(2,9);
        }
      }
    },
  },
  methods: {
    promocodeInput() {
      this.promocodeError = '';
    },
    changePassport() {
      this.data.p_series = '';
      this.data.p_number = '';
      this.isPassport = !this.isPassport;
      this.passportKey += 1;
    },
    deletePassenger() {
      this.$emit('deletePassenger', this.index);
    },
    checkDate(value){
      return value <= (new Date);
    },
    async submitForm(){
      const isFormValid = await this.v$.$validate();
      if (this.data.promocode) {
        try {
          this.promocodeError = '';
          const { data } = await this.axios
            .get(`https://api.vskcorp.ru/aviasales/promocode/?promocode=${this.data.promocode}`);
          this.isPromocodeValid = data.success;
          if (!data.success) {
            this.promocodeError = 'Введите корректный промокод';
          }
        } catch {
          this.isPromocodeValid = false;
          this.promocodeError = 'Введите корректный промокод';
        }
      }
      this.preloader.error = '';
      if (!isFormValid || !this.isAddConfirm || this.isPromocodeValid) {
        if (!this.isAddConfirm) {
          this.preloader.error = 'Заполните шаг 2 для пассажира № 1';
        }
        console.log('error');
        return;
      }
      this.preloader.visible = true;
      const formData = {
        ...this.data,
        cost: this.form.cost,
        connecting_flight: this.form.connecting_flight,
        flight_there: this.form.flight_there,
        flight_back: this.form.flight_back,
        flight_there_datetime: this.form.flight_there_datetime,
        flight_back_datetime: this.form.flight_back_datetime,
      };
      formData.birthday = this.data.birthday.toLocaleDateString('ru', { hour12: false })
        .split('.').reverse().join('-');
      formData.p_date = this.data.p_date.toLocaleDateString('ru', { hour12: false })
        .split('.').reverse().join('-');
      formData.phone = '7' + formData.phone;
      this.preloader.error = null;
      this.preloader.visible = true;
      console.log(formData);
      this.axios.post('https://api.vskcorp.ru/aviasales/buy/', formData)
        .then( response => {
          this.preloader.visible = false;
          this.success = true;
          console.log(response)
        })
        .catch( () => {
          this.preloader.visible = false;
          this.preloader.error = 'Во время отправки данных произошла ошибка';
        });
    },
  },
}
</script>

<style lang="scss">
</style>
