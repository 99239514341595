<template>
  <div class="top">
    <div class="top__left">
      <div class="top__header">
        <img class="top__their-img" :src="require('@/assets/img/their.png')">
        <img class="top__aviasales-img" :src="require('@/assets/img/aviasales.png')">
      </div>
      <div class="top__content">
        <div class="top__content-header">
          Страхование для авиапассажиров
        </div>
        <div class="top__labels">
          <div class="top__label">
            <div class="top__label-header">Удобно</div>
            <mq-responsive target="small+" class="top__label-text">
              Все ваши<br>документы всегда<br>под рукой
            </mq-responsive>
            <mq-responsive target="mobile" class="top__label-text">
              Все ваши документы<br>всегда под рукой
            </mq-responsive>
          </div>
          <div class="top__label">
            <div class="top__label-header">Надежно</div>
            <mq-responsive target="small+" class="top__label-text">
              Защита от<br>непредвиденных<br>ситуаций
            </mq-responsive>
            <mq-responsive target="mobile" class="top__label-text">
              Защита от непредвиденных<br>ситуаций
            </mq-responsive>
          </div>
          <div class="top__label">
            <div class="top__label-header">Быстро</div>
            <mq-responsive target="small+" class="top__label-text">
              Электронный<br>полис придет<br>на ваш email
            </mq-responsive>
            <mq-responsive target="mobile" class="top__label-text">
              Электронный полис<br>придет на ваш email
            </mq-responsive>
          </div>
        </div>
      </div>
      <PButton class="top__button button" label="Оформить полис" @click="scroll"/>
    </div>
    <div class="top__rigth">
      <img class="top__people-img" :src="require('@/assets/img/people.svg')">
      <img class="top__plane-img" :src="require('@/assets/img/plane.png')">
    </div>
  </div>
</template>

<script>
import {MqResponsive} from "vue3-mq"
export default {
  name: 'TopTag',
  components: {
    MqResponsive,
  },
  methods: {
    scroll(){
      const el = document.getElementById('form');
      el.scrollIntoView({behavior: "smooth"});
    }
  },
}
</script>

<style lang="scss">
.top {
  position: relative;
  display: flex;
  width: 100%;
  @media (max-width: 600px){
    flex-direction: column;
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    padding: 4.36rem 5.36rem 7.43rem 11.29rem;
    max-width: 54.64rem;
    @media (max-width: 600px){
      padding: 1.79rem 2.14rem;
    }
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &__content {
    margin-top: 7.29rem;
    margin-bottom: 4.5rem;
    @media (max-width: 600px){
      margin: 2.43rem 0;
    }
  }
  &__content-header {
    font-weight: 600;
    font-size: 3.57rem;
    line-height: 96.9%;
    @media (max-width: 600px){
      font-size: 2.14rem;
    }
  }
  &__content-text {
    font-weight: 400;
    line-height: 122.9%;
    margin-top: 2.29rem;
    @media (max-width: 600px){
      margin-top: 1.14rem;
      font-size: 0.71rem;
    }
  }
  &__labels {
    display: flex;
    justify-content: space-between;
    margin-top: 4.93rem;
    @media (max-width: 600px){
      flex-direction: column;
      margin-top: 1.57rem;
      padding-left: 1.71rem;
    }
  }
  &__label {
    &+&{
      @media (max-width: 600px){
        margin-top: 0.57rem;
      }
    }
  }
  &__label-header {
    font-weight: 600;
    font-size: 1.29rem;
    line-height: 122.9%;
    @media (max-width: 600px){
      font-size: 1rem;
    }
  }
  &__label-text {
    font-weight: 400;
    line-height: 122.9%;
    margin-top: 0.5rem;
    @media (max-width: 600px){
      font-size: 0.7rem;
      margin-top: 0.2rem;
    }
  }
  &__rigth {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding-top: 5.5rem;
    @media (max-width: 600px){
      background-color: #EAF0F8;
      margin: 0 1.36rem;
      padding: 1.43rem 0 2.43rem 0;
    }
  }
  &__their-img{
    width: 7.86rem;
    height: 1.79rem;
    @media (max-width: 600px){
      width: 3.71rem;
      height: 0.86rem;
    }
  }
  &__aviasales-img{
    margin-left: 11.43rem;
    width: 13.57rem;
    height: 2.86rem;
    @media (max-width: 600px){
      margin-left: 5.43rem;
      width: 6.5rem;
      height: 1.36rem;
    }
  }
  &__plane-img{
    position: absolute;
    top: 5.79rem;
    left: 48.29rem;
    width: 10.71rem;
    height: 10.71rem;
    @media (max-width: 600px){
      top: 14rem;
      left: 18.29rem;
      width: 6.86rem;
      height: 6.86rem;
    }
  }
  &__people-img{
    width: 48.07rem;
    height: 28.36rem;
    @media (max-width: 600px){
      width: 20.14rem;
      height: 15.07rem;
    }
  }
}
</style>
