<template>
  <div class="form" id="form">
    <div class="form__left">
      <div v-if="step" class="form__header">
        <div class="form__caption" v-if="step === 1">
          Введите данные пассажира
          <template v-if="isManyPassengers">
            № 1
          </template>
        </div>
        <div class="form__caption" v-else-if="step === 2">
          Введите данные перелета
        </div>
        <div  class="form__step">Шаг {{step}} из 2</div>
      </div>
      <transition name="fade-form">
        <form-step1 v-if="step==1"
          :formdata="data"
          :countries="countries"
          :genderOptions="genderOptions"
          @next="nextStep"
        />
        <form-step2 v-else-if="step==2"
          :formdata="data"
          :preloader="preloader"
          @next="submitForm"
        />
        <div v-else class="form__success">
          <i class="pi pi-check-circle form__success-icon"/>
          <div class="form__success-label">
            Заявка успешно отправлена!
          </div>
          <div class="form__success-text">
            На вашу почту вскоре придут необходимые данные
          </div>
        </div>
      </transition>
    </div>
    <div class="form__right">
      <PButton label="Добавить пассажира" class="form__add-text" @click="addPassenger" />
      <div class="form__devider"/>
      <div class="form__img-wrapper">
        <img class="form__img" :src="require('@/assets/img/form-stuff.png')">
      </div>
    </div>
    <div class="form__devider form__devider--mobile"/>
  </div>
</template>

<script>
import FormStep1 from '../components/FormStep1.vue'
import FormStep2 from '../components/FormStep2.vue'
export default {
  name: 'FormTag',
  components: {
    FormStep1,
    FormStep2,
  },
  props: {
    countries: Array,
    genderOptions: Array,
    isManyPassengers: Boolean,
  },
  data(){
    return{
      step: 1,
      data:{
        first_name: '',
        second_name: '',
        third_name: '',
        birthday: '',
        p_series: '',
        p_number: '',
        p_date: '',
        phone: '',
        email: '',
        gender: null,
        citizenship: '',
        cost: null,
        connecting_flight: false,
        flight_there: '',
        flight_back: '',
        flight_there_datetime: '',
        flight_back_datetime: '',

        // first_name: 'Иванов',
        // second_name: 'Иван',
        // third_name: 'Иванович',
        // birthday: '',
        // p_series: '4444',
        // p_number: '444444',
        // p_date: '',
        // phone: '9667678787',
        // email: 'ivan@mail.ru',
        // gender: 1,
        // inn: '7731374156',
        // kpp: '773101001',
        // jur_name: 'Общество с ограниченной ответственностью  «Аквамарин»',
        // cost: 123123,
        // connecting_flight: false,
        // start_date_fly: '',
        // end_date_fly: '',
        // flight_there: 'LH-1051',
        // flight_back: 'LH-1051',
        // flight_there_datetime: '',
        // flight_back_datetime: '',
      },
      preloader: {
        error: '',
        visible: false,
      },
    }
  },
  methods: {
    nextStep(data){
      this.step = this.step + 1;
      Object.assign(this.data, data);
    },
    addPassenger(){
      this.$emit('addPassenger');
    },
    submitForm(data){
      this.preloader.visible = true;
      Object.assign(this.data, data);
      let formData = Object.assign({}, this.data);
      formData.birthday = this.data.birthday.toLocaleDateString('ru', { hour12: false })
        .split('.').reverse().join('-');
      formData.p_date = this.data.p_date.toLocaleDateString('ru', { hour12: false })
        .split('.').reverse().join('-');
      formData.phone = '7' + formData.phone;
      this.preloader.error = null;
      this.preloader.visible = true;
      this.axios.post('https://api.vskcorp.ru/aviasales/buy/', formData)
        .then( response => {
          this.preloader.visible = false;
          this.step = null;
          this.$emit('confirmAdd', formData);
          console.log(response)
        })
        .catch( () => {
          this.preloader.visible = false;
          this.preloader.error = 'Во время отправки данных произошла ошибка';
        });
    },
  },
}
</script>

<style lang="scss">
.form {
  display: flex;
  width: 100%;
  background: #FFFFFF;
  max-width: 65.07rem;
  box-shadow: 0.15rem 0.30rem 2.07rem rgba(0, 63, 106, 0.2), -3.5rem 5rem 8.21rem rgba(0, 44, 73, 0.1);
  border-radius: 0.71rem;
  border-top: solid 0.8rem #3478F6;
  padding: 4.43rem 3.14rem;
  @media (max-width: 600px){
  flex-direction: column;
  border-top: solid 0.4rem #3478F6;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  padding-top: 2.14rem;
  margin: 0;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -7.14rem;
    margin-bottom: 10rem;
    @media (max-width: 600px){
      margin: 0;
    }
  }
  &__left {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-right: 2.57rem;
    max-width: 40.79rem;
    @media (max-width: 600px){
    padding: 0 2.14rem;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__caption {
    font-weight: 600;
    line-height: 96.9%;
    font-size: 1.57rem;
  }
  &__step {
    font-weight: 500;
    line-height: 96.9%;
    color: #646464;
    font-size: 1rem;
    @media (max-width: 600px){
    font-size: .71rem;
    white-space: nowrap;
    }
  }
  &__row {
    position: relative;
    display: flex;
    padding-top: 1.79rem;
    &--error {
      justify-content: center;
    }
    @media (max-width: 600px){
    flex-direction: column;
    padding-top: 0;
    }
  }
  &__error {
    color: red;
    font-size: 1rem;
    text-align: center;
  }
  &__field {
    height: 3rem;
    @media (max-width: 600px){
    width: 100%;
    margin: 1.6rem 0 0 0;
    }
    &--error {
      margin-bottom: .5rem;
      .form__select-button .p-button,
      .p-inputwrapper,
      .p-dropdown,
      .p-inputtext {
        border-color: #e24c4c;
      }
      &.p-float-label > label {
        color: #e24c4c;
      }
    }
    &--flex {
      &--1 {
        flex: 1;
      }
    }
    & + & {
      margin-left: .75rem;
      @media (max-width: 600px){
      margin-left: 0;
      }
    }
    .p-inputtext,
    .p-inputwrapper,
    .p-calendar {
      width: 100%;
      height: 100%;
      @media (max-width: 600px){
      height: 3rem;
      }
    }
  }
  &__dropdown {
    width: 100%;
    height: 100%;
    @media (max-width: 600px){
      height: 3rem;
    }
  }
  &__validate {
    position: absolute;
    bottom: -3px;
    transform: translateY(100%);
    left: 0;
    font-size: .7rem;
    &::before {
      content: '*';
      margin-right: 2px;
    }
  }
  &__select-button {
    height: 100%;
    @media (max-width: 600px){
    display: flex;
    }
    .p-button {
      height: 100%;
      @media (max-width: 600px){
      flex: 1;
      }
    }
  }
  &__detail {
    display: flex;
    justify-content: flex-start;
  }
  &__detail-link {
    font-weight: 500;
    line-height: 96.9%;
    color: #3478F6;
    font-size: 0.86rem;
    padding: 1.21rem;
    cursor: pointer;
    @media (max-width: 600px){
    padding: 1rem;
    margin-left: -1rem;
    font-size: 0.71rem;
    }
  }
  &__devider {
    height: 2px;
    background-color: #EAF0F8;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    @media (max-width: 600px){
    display: none;
    }
    &--mobile{
      display: none;
      margin: 0;
      @media (max-width: 600px){
      display: block;
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--flex-end {
      justify-content: flex-end;
    }
    @media (max-width: 600px){
    margin: 1rem 0 2rem 0;
    }
  }
  &__checkbox {
  @media (max-width: 600px){
  margin-bottom: 1rem;
  }
  }
  &__checkbox-text {
    font-weight: 500;
    font-size: .71rem;
    line-height: 96.9%;
    color: #646464;
    padding-left: 1rem;
    max-width: 14rem;
    &--form {
      font-weight: 400;
      font-size: 1rem;
      color: #6c757d;
    }
    &--error {
      color: #e24c4c;
    }
    @media (max-width: 600px){
    font-size: 0.57rem;
    max-width: 10rem;
    }
  }
  &__button {
  }
  &__right {
    position: relative;
    max-width: 18rem;
    width: 18rem;
    display: flex;
    flex-direction: column;
    @media (max-width: 600px){
    display: none;
    padding: 2rem 2.14rem 0 2.14rem;
    max-width: none;
    width: 100%;
    }
  }
  &__text {
    font-weight: 500;
    line-height: 96.9%;
    color: #646464;
    font-size: 0.86rem;
    max-width: 15.57rem;
  }
  &__big-text {
    font-weight: 600;
    font-size: 1.14rem;
    line-height: 96.9%;
    color: #646464;
    margin-bottom: 1.46rem;
    @media (max-width: 600px){
    font-size: 1rem;
    }
  }
  &__text-bottom {
    font-weight: 500;
    line-height: 96.9%;
    color: #A2A2A2;
    font-size: 0.86rem;
    margin-bottom: 2.6rem;
    max-width: 15.57rem;
    @media (max-width: 600px){
    max-width: 11.36rem;
    font-size: 0.71rem;
    }
  }
  &__img-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
  }
  &__img{
    align-self: center;
    width: 13rem;
    height: 13rem;
    @media (max-width: 600px){
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-right: 2.14rem;
    width: 9.43rem;
    height: 9.43rem;
    }
  }
  &__success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 17.86rem;
  }
  &__success-icon.pi {
    font-size: 7rem;
    color: #22C55E;
  }
  &__success-label {
    margin-top: 1rem;
    font-size: 1.3rem;
    font-weight: 500;
  }
  &__success-text {
    margin-top: 1rem;
  }
  &__add-button.p-button {
    margin-top: 2rem;
    padding: 1.06rem 2rem;
  }
  &__add-text.p-button {
    font-weight: 600;
    line-height: 96.9%;
    color: #3478F6;
    font-size: 1.3rem;
    user-select: none;
    cursor: pointer;
    transition: background .2s ease, color .2s ease;
    padding: .75rem 1rem;
    margin-bottom: -.5rem;
    background: none;
    white-space: nowrap;
    border: none;
    &:hover {
      background: #3478F6;
      color: #fff;
    }
  }
}

#app {
  .p-dropdown-label.p-inputtext {
    display: flex;
    align-items: center;
  }
}
.p-dropdown-panel {
  .p-dropdown-items-wrapper {
    @media (max-width: 600px) {
    max-width: 84vw;
    }
  }
  .p-dropdown-item {
    @media (max-width: 600px) {
    white-space: normal;

    }
  }
}

.fade-form-enter-active,
.fade-form-leave-active {
  transition: opacity 0.3s ease;
}

.fade-form-leave-active {
  position: absolute;
  top: 1.5rem;
  width: 37.86rem;
}

.fade-form-enter-from,
.fade-form-leave-to {
  opacity: 0;
}

</style>
