<template>
  <form class="form__content" @submit.prevent="submitForm(!v$.$invalid)">
    <div class="form__row">
      <div class="p-float-label form__field form__field--flex--1"
        :class="{'form__field--error':v$.data.first_name.$error}">
        <PInputText id="first_name" type="text" v-model="data.first_name" />
        <label for="first_name">Фамилия</label>
        <div v-if="v$.data.first_name.$error"
          class="form__validate p-error">
          {{v$.data.first_name.$errors[0].$message}}
        </div>
      </div>
      <div class="p-float-label form__field form__field--flex--1"
        :class="{'form__field--error':v$.data.second_name.$error}">
        <PInputText id="second_name" type="text" v-model="data.second_name" />
        <label for="second_name">Имя</label>
        <div v-if="v$.data.second_name.$error"
          class="form__validate p-error">
          {{v$.data.second_name.$errors[0].$message}}
        </div>
      </div>
      <div class="p-float-label form__field form__field--flex--1"
        :class="{'form__field--error':v$.data.third_name.$error}">
        <PInputText id="third_name" type="text" v-model="data.third_name" />
        <label for="third_name">Отчество</label>
        <div v-if="v$.data.third_name.$error"
          class="form__validate p-error">
          {{v$.data.third_name.$errors[0].$message}}
        </div>
      </div>
    </div>
    <div class="form__row">
      <mq-responsive target="small+" class="p-float-label form__field form__field--flex--1"
        :class="{'form__field--error':v$.data.birthday.$error}">
        <PCalendar id="birthday" v-model="data.birthday" :showIcon="true"
          :maxDate="now"/>
        <label for="birthday">Дата рождения</label>
        <div v-if="v$.data.birthday.$error"
          class="form__validate p-error">
          {{v$.data.birthday.$errors[0].$message}}
        </div>
      </mq-responsive>
      <mq-responsive target="mobile" class="p-float-label form__field form__field--flex--1"
        :class="{'form__field--error':v$.data.birthday.$error}">
        <PCalendar id="birthday" v-model="data.birthday" :showIcon="true" :touchUI="true"
          :maxDate="now"/>
        <label for="birthday">Дата рождения</label>
        <div v-if="v$.data.birthday.$error"
          class="form__validate p-error">
          {{v$.data.birthday.$errors[0].$message}}
        </div>
      </mq-responsive>
      <div class="p-float-label form__field"
        :class="{'form__field--error':v$.data.gender.$error}">
        <SelectButton v-model="data.gender" :options="genderOptions"
          optionLabel="label" optionValue="value"
          class="form__select-button"
        />
        <div v-if="v$.data.gender.$error"
          class="form__validate p-error">
          {{v$.data.gender.$errors[0].$message}}
        </div>
      </div>
      <div class="p-float-label form__field form__field--flex--1"
        :class="{'form__field--error':v$.data.citizenship.$error}">
        <PDropdown v-model="data.citizenship"
          class="form__dropdown"
          optionLabel="label"
          optionValue="label"
          filter
          :options="countries"
        />
        <label for="p_series">Гражданство</label>
        <div v-if="v$.data.citizenship.$error"
          class="form__validate p-error">
          {{v$.data.citizenship.$errors[0].$message}}
        </div>
      </div>
    </div>
    <div class="form__row">
      <div class="p-float-label form__field form__field--flex--1"
        :class="{'form__field--error':v$.passport.$error}">
        <InputMask :mask="passportMask" id="p_number" type="text" v-model="passport"
          unmask :autoClear="false" :key="passportKey" />
        <label for="p_number">Серия и номер {{isPassport ? 'паспорта' : 'загранпаспорта'}}</label>
        <div class="form__select-passport-type" @click="changePassport">
          <span v-if="isPassport">Использовать загранпаспорт</span>
          <span v-else>Использовать паспорт</span>
        </div>
        <div v-if="v$.passport.$error"
          class="form__validate p-error">
          {{v$.passport.$errors[0].$message}}
        </div>
      </div>
      <mq-responsive target="small+" class="p-float-label form__field form__field--flex--1"
        :class="{'form__field--error':v$.data.p_date.$error}">
        <PCalendar id="p_date" v-model="data.p_date" :showIcon="true"
          :maxDate="now" :minDate="data.birthday || null"/>
        <label for="p_date">Дата выдачи паспорта</label>
        <div v-if="v$.data.p_date.$error"
          class="form__validate p-error">
          {{v$.data.p_date.$errors[0].$message}}
        </div>
      </mq-responsive>
      <mq-responsive target="mobile" class="p-float-label form__field form__field--flex--1"
        :class="{'form__field--error':v$.data.p_date.$error}">
        <PCalendar id="p_date" v-model="data.p_date" :showIcon="true" :touchUI="true"
          :maxDate="now" :minDate="data.birthday || null"/>
        <label for="p_date">Дата выдачи паспорта</label>
        <div v-if="v$.data.p_date.$error"
          class="form__validate p-error">
          {{v$.data.p_date.$errors[0].$message}}
        </div>
      </mq-responsive>
    </div>
    <div class="form__row">
      <div class="p-float-label form__field form__field--flex--1"
        :class="{'form__field--error':v$.data.phone.$error}">
        <InputMask mask="+7 (999) 999-9999" id="phone" type="text" v-model="data.phone"
          :unmask="true" :autoClear="false" />
        <label for="phone">Телефон</label>
        <div v-if="v$.data.phone.$error"
          class="form__validate p-error">
          {{v$.data.phone.$errors[0].$message}}
        </div>
      </div>
      <div class="p-float-label form__field form__field--flex--1"
        :class="{'form__field--error':v$.data.email.$error}">
        <PInputText id="email" type="email" v-model="data.email" />
        <label for="email">Почта</label>
        <div v-if="v$.data.email.$error"
          class="form__validate p-error">
          {{v$.data.email.$errors[0].$message}}
        </div>
      </div>
      <div class="p-float-label form__field form__field--flex--1"
        :class="{'form__field--error':v$.data.promocode.$error || promocodeError}">
        <PInputText id="promocode"
          type="text"
          v-model="data.promocode"
          @input="promocodeInput"
        />
        <label for="promocode">Промокод</label>
        <div v-if="v$.data.promocode.$error || promocodeError"
          class="form__validate p-error">
          <template v-if="promocodeError">
            {{promocodeError}}
          </template>
          <template v-else>
            {{v$.data.promocode.$errors[0].$message}}
          </template>
        </div>
      </div>
    </div>
    <div class="form__row"></div>
    <div class="form__devider"/>
    <div class="form__footer form__footer--flex-end">
      <PButton class="form__button button" type="submit" label="Далее"/>
    </div>
  </form>
</template>

<script>
import {MqResponsive} from "vue3-mq"
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators'
export default {
  name: 'FormStep1',
  components: {
    MqResponsive,
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      passport: {
        required: helpers.withMessage('Поле является обязательным', required),
        minLength: helpers.withMessage('Введите корректное значение', minLength(this.passportLenght)),
      },
      data: {
        first_name: {
          required: helpers.withMessage('Поле является обязательным', required),
        },
        second_name: {
          required: helpers.withMessage('Поле является обязательным', required),
        },
        third_name: {
          required: helpers.withMessage('Поле является обязательным', required),
        },
        birthday: {
          required: helpers.withMessage('Поле является обязательным', required),
          checkDate: helpers.withMessage('Введите корректную дату', this.checkDate),
        },
        p_date: {
          required: helpers.withMessage('Поле является обязательным', required),
          checkDate: helpers.withMessage('Введите корректную дату', this.checkDate),
        },
        gender: {
          required: helpers.withMessage('Поле является обязательным', required),
        },
        email: {
          required: helpers.withMessage('Поле является обязательным', required),
          email: helpers.withMessage('Введите корректный email', email),
        },
        phone: {
          required: helpers.withMessage('Поле является обязательным', required),
          minLength: helpers.withMessage('Введите корректное значение', minLength(10)),
        },
        citizenship: {
          required: helpers.withMessage('Поле является обязательным', required),
        },
        promocode: {
          required: helpers.withMessage('Поле является обязательным', required),
        },
      }
    }
  },
  props: {
    formdata: Object,
    countries: Array,
    genderOptions: Array,
  },
  data(){
    return{
      country: null,
      isPassport: true,
      passportKey: 1,
      isPromocodeValid: false,
      promocodeError: '',
      data:{
        first_name: '',
        second_name: '',
        third_name: '',
        birthday: '',
        p_series: '',
        p_number: '',
        p_date: '',
        phone: '',
        email: '',
        gender: null,
        citizenship: '',
        promocode: '',
      },
      now: new Date(),
    }
  },
  computed: {
    passportMask() {
      return this.isPassport ? '9999 999999' : '99 9999999'
    },
    passportLenght() {
      return this.isPassport ? 10 : 9;
    },
    passport: {
      get() {
        return this.data.p_series + this.data.p_number;
      },
      set(value) {
        if (this.isPassport) {
          this.data.p_series = value.slice(0,4);
          this.data.p_number = value.slice(4,10);
        } else {
          this.data.p_series = value.slice(0,2);
          this.data.p_number = value.slice(2,9);
        }
      }
    },
  },
  mounted(){
    this.data.first_name = this.formdata.first_name;
    this.data.second_name = this.formdata.second_name;
    this.data.third_name = this.formdata.third_name;
    this.data.birthday = this.formdata.birthday;
    this.data.p_series = this.formdata.p_series;
    this.data.p_number = this.formdata.p_number;
    this.data.p_date = this.formdata.p_date;
    this.data.phone = this.formdata.phone;
    this.data.email = this.formdata.email;
    this.data.gender = this.formdata.gender;
  },
  methods: {
    promocodeInput() {
      this.promocodeError = '';
    },
    changePassport() {
      this.data.p_series = '';
      this.data.p_number = '';
      this.isPassport = !this.isPassport;
      this.passportKey += 1;
    },
    checkDate(value){
      return value <= (new Date);
    },
    async submitForm(){
      const isFormValid = await this.v$.$validate();
      if (this.data.promocode) {
        try {
          this.promocodeError = '';
          const { data } = await this.axios
            .get(`https://api.vskcorp.ru/aviasales/promocode/?promocode=${this.data.promocode}`);
          this.isPromocodeValid = data.success;
          if (!data.success) {
            this.promocodeError = 'Введите корректный промокод';
          }
        } catch {
          this.isPromocodeValid = false;
          this.promocodeError = 'Введите корректный промокод';
        }
      }
      if (!isFormValid || !this.isPromocodeValid) {
        console.log('error');
        return;
      }
      console.log('success');
      this.$emit('next', this.data);
    },
  },
}
</script>

<style lang="scss">
.form {
  &__select-passport-type {
    position: absolute;
    color: #3B82F6;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
    top: -1.2rem;
    left: .5rem;
    font-size: .9rem;
    transition: opacity .15s ease;
    opacity: 1;
  }
  .p-float-label input.p-filled ~ label ~ &__select-passport-type,
  .p-float-label input:focus ~ label ~ &__select-passport-type {
    opacity: 0;
  }
}
</style>
