<template>
  <div class="main">
    <div class="main__content">
      <top-tag/>
      <step-tag/>
      <forms-wrapper/>
      <middle-tag/>
    </div>
  </div>
  <div class="footer">
    <div class="footer__content">
      <bottom-tag/>
    </div>
  </div>
</template>

<script>
import TopTag from './components/TopTag.vue'
import StepTag from './components/StepTag.vue'
import FormsWrapper from './components/FormsWrapper.vue'
import MiddleTag from './components/MiddleTag.vue'
import BottomTag from './components/BottomTag.vue'
export default {
  name: 'App',
  components: {
    TopTag,
    StepTag,
    FormsWrapper,
    MiddleTag,
    BottomTag,
  }
}
</script>

<style lang="scss">
@import 'assets/null.css';
@import 'assets/fonts/montserrat/stylesheet.css';
@import 'assets/custom.scss';

@import 'primevue/resources/themes/lara-light-blue/theme.css';
@import 'primevue/resources/primevue.min.css';
@import 'primeicons/primeicons.css';

html{
  font-size: 14px;
  @media (max-width: 1440px){
    @include font-size-a(14);
  }
  @media (max-width: 600px){
    @include font-size-am(14);
  }
}
body{
  display: flex;
  flex-direction: column;
}
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
  font-family: Montserrat;
  width: 100%;
  flex: 1;
  background: linear-gradient(to bottom, #EAF0F8, #ffffff);
  @media (max-width: 600px){
  background: #FFFFFF;
  }
}
.main{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: linear-gradient(to bottom, #EAF0F8, #ffffff);
  @media (max-width: 600px){
  background: #FFFFFF;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    width: 100%;
    flex: 1;
  }
}
.footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #3478F6;
  &__content {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
    flex: 1;
  }
}
</style>
