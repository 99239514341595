<template>
  <div class="middle">
    <div class="middle__conditions middle__border-bottom">
      <div class="middle__conditions-left">Условия</div>
      <div class="middle__conditions-right">
        <cu-accordion class="middle__accordion">
          <template v-slot:header>
            <mq-responsive target="small+">
              Полис страхования, Публичная оферта, Приложения
            </mq-responsive>
            <mq-responsive target="mobile">
              Полис страхования, Публичная оферта, Приложения
            </mq-responsive>
          </template>
          <div class="middle__accordion-content">
            <a
              class="middle__accordion-file"
              href="/pravila-201.pdf"
              target="_blank"
            >
              Правила № 201<br>
              Комбинированного страхования авиапассажиров (.pdf)
            </a>
            <a
              class="middle__accordion-file"
              href="/oferta.pdf"
              target="_blank"
            >
              Публичная оферта (.pdf)
            </a>
            <a
              class="middle__accordion-file"
              href="/prilojenie-1.pdf"
              target="_blank"
            >
              Приложение 1 (.pdf)
            </a>
            <a
              class="middle__accordion-file"
              href="/prilojenie-2.pdf"
              target="_blank"
            >
              Приложение 2 (.pdf)
            </a>
          </div>
        </cu-accordion>
        <cu-accordion class="middle__accordion">
          <template v-slot:header>
            <mq-responsive target="small+">
              Условия страхования
            </mq-responsive>
            <mq-responsive target="mobile">
              Условия страхования
            </mq-responsive>
          </template>
          <div class="middle__accordion-content">
            <div class="middle__accordion-item">
              Полисом предусмотрены следующие страховые риски:<br>
              • Задержка вылета рейса на 240 минут и более;<br>
              • Отмена перевозчиком рейса за день до планового вылета<br>
              или позже;
              • Страхование на случай невозможности совершить поездку (закрытый перечень причин
              отмены поездки);<br>
              • Страхование от несчастных случаев: Травма в результате несчастного случая»;
              Инвалидность в результате несчастного случая; Смерть в результате несчастного случая;
              Смерть в результате террористического акта.
            </div>
            <div class="middle__accordion-item">
              Для каждого страхового риска, указанного в полисе определена
              территория страхования:<br>
              • «Задержка вылета рейса», «Отмена рейса перевозчиком», «Расходы на
              восстановление документов», «Страхование на случай невозможности совершить
              поездку»: договор страхования действует на территории всего мира.<br>
              • «Страхование от несчастных случаев»: в самолете, осуществляющем перевозку;
              на территории аэропорта, в котором перевозка начинается и заканчивается,
              за исключением территорий (помещений), не предназначенных для нахождения пассажиров.
            </div>
            <div class="middle__accordion-item">
              • Расходы, связанные с получением справок, требующихся для урегулирования
              страхового случая, не возмещаются.<br>
              • Расходы, связанные с отправкой оригиналов документов в страховую компанию,
              не возмещаются.<br>
              • Расходы понесенные Застрахованным, связанные с организацией экспертизы по
              страховому случаю, не возмещаются.
            </div>
          </div>
        </cu-accordion>
      </div>
    </div>
    <div class="middle__instructions middle__border-bottom">
      <div class="middle__instructions-left">
        <span>Инструкции</span>
        <mq-responsive target="small+">
          <img :src="require('@/assets/img/stuff.png')"
            class="middle__instructions-img">
        </mq-responsive>
      </div>
      <div class="middle__instructions-right">
        <div class="middle__instructions-label">
          Что делать, если у вас произошли неприятности
        </div>
        <div class="middle__instructions-text">
          Чаще всего страхование помогает пассажирам при задержке рейса,
          повреждении и потере багажа, хотя распространяется и на несчастные случаи.
          Если вам предстоит перелет и вы хотите защитить себя и багаж,
          оформите полис на сайте.
        </div>
        <cu-accordion class="middle__accordion">
          <template v-slot:header>
            <mq-responsive target="small+">
              Если рейс отменили?
            </mq-responsive>
            <mq-responsive target="mobile">
              Если рейс отменили?
            </mq-responsive>
          </template>
          <div class="middle__accordion-content">
            <div class="middle__accordion-text">
              При «Отмене рейса перевозчиком»:<br>
              1.  Обратитесь в компетентные органы аэропорта для получения ОДНОГО из
              следующих документов:<br>
              • заверенную справку от авиакомпании об отмене рейса (могут быть рассмотрены
               почтовые уведомления от авиакомпании или смс оповещения);<br>
              2.  Направьте заявление в САО «ВСК» на сайте
              <a class="middle__text--site"
                href="https://www.vsk.ru"
                target="_blank"
              >
                www.vsk.ru
              </a>
              в разделе
              <a class="middle__text--site"
                href="https://www.vsk.ru/event/"
                target="_blank"
              >
                «Страховой случай»
              </a>
              <br>
              3.  Приложите к заявлению копии документов:<br>
              • Страховой полис; Билет (маршрут-квитанция электронного билета);<br>
              • Заверенную справку от авиакомпании об отмене рейса (могут быть
              рассмотрены почтовые уведомления от авиакомпании или смс оповещения);<br>
              • Оплаченные билеты на регулярный авиационный или железнодорожный, или
              автобусный транспорт, которым была фактически осуществлена перевозка,
              с указанием даты и времени ее осуществления (или посадочные талоны);<br>
              • Паспорт;<br>
              • Банковские реквизиты.
            </div>
          </div>
        </cu-accordion>
        <cu-accordion class="middle__accordion">
          <template v-slot:header>
            <mq-responsive target="small+">
              Если рейс задержали?
            </mq-responsive>
            <mq-responsive target="mobile">
              Если рейс задержали?
            </mq-responsive>
          </template>
          <div class="middle__accordion-content">
            <div class="middle__accordion-text">
              При  задержке рейса Вам будет направлено СМС-сообщение<br>
              с информированием о задержке рейса и возможностью <br>
              получить on-line выплату по данному риску.<br>
              <br>
              1. Перейдите по ссылке в СМС<br>
              2. Подтвердите Ваши данные<br>
              3. Заполните заявление<br>
              4. Укажите банковские реквизиты<br>
              5. Получите Выплату.
            </div>
          </div>
        </cu-accordion>
        <cu-accordion class="middle__accordion">
          <template v-slot:header>
            <mq-responsive target="small+">
              Если произошел несчастный случай<br>
              или вы не смогли совершить поездку
            </mq-responsive>
            <mq-responsive target="mobile">
              Если произошел несчастный случай<br>
              или вы не смогли совершить поездку
            </mq-responsive>
          </template>
          <div class="middle__accordion-content">
            <div class="middle__accordion-text">
              Если произошел НС или Вы не смогли совершить поездку<br><br>

              Выполните простой алгоритм действий<br><br>

              1. Зайдите на сайт Страховщика www.vsk.ru или страницу в
              Личном кабинете (далее Страница ДУ)<br>
              2. Заполните заявление на страховую выплату<br>
              3. Приложите сканы документов в соответствии с формой<br>
              4. После принятия решения по выплате пришлите оригиналы документов в СК<br>
              5. Получите выплату

            </div>
          </div>
        </cu-accordion>
      </div>
    </div>
    <div class="middle__bottom">
      <div class="middle__bottom-text">
        При добровольном или вынужденном изменении направления,<br>
        дат перевозки и/или рейсов, указанных в полисе и в электронном<br>
        билете Застрахованного (в случае если номер заказа (PNR) и/или<br>
        авиабилета остаются без изменений), договор страхования<br>
        не подлежит переоформлению и продолжает действовать<br>
        в отношении измененных дат перевозки и/или рейсов.
      </div>
      <img :src="require('@/assets/img/window.png')" class="middle__bottom-img">
    </div>
  </div>
</template>

<script>
import CuAccordion from './CuAccordion.vue'
import {MqResponsive} from "vue3-mq"
export default {
  name: 'MiddleTag',
  components: {
    CuAccordion,
    MqResponsive,
  },
}
</script>

<style lang="scss">
.middle {
  padding: 1.14rem 11.43rem 0 11.43rem;
  @media (max-width: 600px){
  padding: 0;
  }
  &__header {
    font-weight: 600;
    line-height: 96.9%;
    font-size: 3.57rem;
    padding-bottom: 3.2rem;
    padding-top: 3.2rem;
    @media (max-width: 600px){
    padding: 1.43rem 2.14rem 0.86rem 2.14rem;
    font-size: 1rem;
    &.middle__border-bottom{
      border: none;
    }
    }
  }
  &__border-bottom {
    border-bottom: 2px solid #C9D2E0;
    @media (max-width: 600px){
    border-bottom: 2px solid #EAF0F8;
    }
  }
  &__text {
    line-height: 122.9%;
    font-size: 1.71rem;
    width: 100%;
    padding: 2rem 0 2.43rem 0;
    @media (max-width: 600px){
    padding: 0 2.14rem 1.43rem 2.14rem;
    color: #A2A2A2;
    font-size: 0.71rem;
    font-weight: 500;
    }
    &--site {
      text-decoration: underline;
      cursor: pointer;
      color: #000000;
    }
    &--mobile-color {
      @media (max-width: 600px){
      color: #A2A2A2;
      }
    }
  }
  &__conditions {
    display: flex;
    padding: 2.2rem 0;
    @media (max-width: 600px){
    padding: 1.43rem 2.14rem;
    flex-direction: column;
    }
  }
  &__conditions-left {
    font-weight: 600;
    line-height: 96.9%;
    font-size: 2.57rem;
    margin-right: 14.9rem;
    @media (max-width: 600px){
    margin-right: 0;
    margin-bottom: 1.79rem;
    font-size: 1rem;
    }
  }
  &__conditions-right {
    flex: 1;
  }
  &__instructions {
    display: flex;
    padding: 2.2rem 0;
    @media (max-width: 600px){
    padding: 1.43rem 2.14rem;
    flex-direction: column;
    }
  }
  &__instructions-left {
    font-weight: 600;
    line-height: 96.9%;
    font-size: 2.57rem;
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    @media (max-width: 600px){
    font-size: 1rem;
    margin-bottom: .4rem;
    }
  }
  &__instructions-img {
    width: 20.43rem;
    height: 19.29rem;
    margin-top: 6.5rem;
    margin-right: 6.14rem;
  }
  &__instructions-right {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__instructions-label {
    font-weight: 600;
    line-height: 122.9%;
    font-size: 1.57rem;
    @media (max-width: 600px){
    font-size: .85rem;
    }
  }
  &__instructions-text {
    line-height: 122.9%;
    font-size: 1.29rem;
    margin: 2.2rem 0;
    @media (max-width: 600px){
    font-size: .71rem;
    color: #A2A2A2;
    margin: 1rem 0;
    }
  }
  &__accordion {
    @media (max-width: 600px){
      margin: 0 -0.71rem;
    }
    &+&{
      margin-top: 1.79rem;
      @media (max-width: 600px){
      margin-top: 1.43rem;
      }
    }
  }
  &__accordion-content {
    padding: 1.57rem;
    @media (max-width: 600px){
    padding: .8rem;
    }
  }
  &__accordion-item {
    position: relative;
    padding-left: 4.14rem;
    font-size: 1.29rem;
    line-height: 122.9%;
    @media (max-width: 600px){
    font-size: .8rem;
    padding-left: 2rem;
    }
    &::before {
      position: absolute;
      content: '';
      background-color: #3478F6;
      left: 0;
      width: 2.79rem;
      height: 0.21rem;
      margin-top: 0.74rem;
      @media (max-width: 600px){
      width: 1.5rem;
      margin-top: .4rem;
      }
    }
    & + & {
      margin-top: 2.14rem;
      @media (max-width: 600px){
      margin-top: 1rem;
      }
    }
  }
  &__accordion-file {
    display: block;
    color: #3478F6;
    font-weight: 600;
    line-height: 122.9%;
    font-size: 1.29rem;
    & + & {
      margin-top: 1.57rem;
    }
    @media (max-width: 600px){
    font-size: .9rem;
    }
  }
  &__accordion-text {
    line-height: 122.9%;
    font-size: 1.29rem;
    @media (max-width: 600px){
    font-size: .9rem;
    }
  }
  &__bottom {
    padding: 3.2rem 0 4.07rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 600px){
    padding: 1.43rem 2.14rem;
    align-items: center;
    }
  }
  &__bottom-text {
    line-height: 122.9%;
    font-size: 1.57rem;
    max-width: 46.64rem;
    white-space: nowrap;
    @media (max-width: 600px){
    white-space: normal;
    margin-top: 0rem;
    margin-right: 1rem;
    font-size: .57rem;
    font-weight: 500;
    line-height: 111.9%;
    }
  }
  &__bottom-img {
    width: 21.86rem;
    height: 13.5rem;
    @media (max-width: 600px){
    width: 9.64rem;
    height: 7.43rem;
    }
  }
}

</style>
