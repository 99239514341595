<template>
  <div class="step__header-wrapper">
    <div class="step__middle">
      <div class="middle__header middle__border-bottom">
        Страховой полис в подарок
      </div>
      <div class="middle__text">
        Полис предоставляется в рамках программы лояльности «Свои»<br>
        при покупке билета через интернет-сайт
        <a class="middle__text--site middle__text--mobile-color"
          href="https://www.aviasales.ru"
          target="_blank"
        >
          www.aviasales.ru
        </a>
        <br>
        Полис страхования, оформляемый в рамках текущего предложения,<br>
        не требует оплаты.
      </div>
    </div>
    <div class="step">
      <div class="step__header">
        Летайте под защитой ВСК
      </div>
      <div class="step__content">
        <div class="step__card step__card--1">
          <div class="step__icon">1</div>
          <div class="step__text">
            Введите данные<br>о перелете
          </div>
        </div>
        <mq-responsive class="step__line" target="small+"><br></mq-responsive>
        <div class="step__card step__card--2">
          <div class="step__icon">2</div>
          <div class="step__text">
            Оформите заявку<br>на получение полиса
          </div>
        </div>
        <mq-responsive class="step__line" target="small+"><br></mq-responsive>
        <div class="step__card step__card--3">
          <div class="step__icon">3</div>
          <div class="step__text">
            Вышлем вам на почту<br>все необходимое
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MqResponsive} from "vue3-mq"
export default {
  name: 'StepTag',
  components: {
    MqResponsive,
  },
}
</script>

<style lang="scss">
.step {
  background-color: #ffffff;
  padding: 4.93rem 18.21rem 15.43rem 18.21rem;
  width: 100%;
  @media (max-width: 600px){
  padding: 1rem 2.14rem 3rem;
  }
  &__header-wrapper {
    width: 100%;
    background: white;
  }
  &__middle {
    padding: 1.14rem 11.43rem 0 11.43rem;
    @media (max-width: 600px) {
      padding: 0;
      padding-top: 1.5rem;
    }
  }
  &__header {
    font-weight: 600;
    line-height: 96.9%;
    text-align: center;
    font-size: 2.14rem;
    margin-bottom: 4.71rem;
    @media (max-width: 600px){
    font-size: 1.57rem;
    margin-bottom: 2.36rem;
    }
  }
  &__content {
    display: flex;
    @media (max-width: 600px){
    flex-direction: column;
    }
  }
  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 600px){
    flex-direction: row;
    align-items: center;
    }
    &--1{
      margin-right: 1.86rem;
      @media (max-width: 600px){
        margin: 0;
      }
    }
    &--2{
      margin: 0 0.71rem;
      @media (max-width: 600px){
        margin: 0;
      }
    }
    &--3{
      margin-left: 0.5rem;
      @media (max-width: 600px){
        margin: 0;
      }
    }
    &+&{
      @media (max-width: 600px){
        margin-top: 1.5rem;
      }
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EAF0F8;
    line-height: 96.9%;
    text-align: center;
    color: #646464;
    border-radius: 1.43rem;
    font-size: 2.14rem;
    height: 5.64rem;
    width: 5.64rem;
    margin-bottom: 1.36rem;
    @media (max-width: 600px){
    margin: 0 2.5rem 0 0;
    }
  }
  &__text {
    line-height: 122.9%;
    text-align: center;
    @media (max-width: 600px){
    text-align: left;
    }
  }
  &__line {
    flex: 1;
    background: #EAF0F8;
    height: 0.29rem;
    border-radius: 0.14rem;
    margin-top: 2.86rem;
    @media (max-width: 600px){
    display: none;
    }
  }
}
</style>
