<template>
  <div class="bottom">
    <div class="bottom__row bottom__row--1">
      <div class="bottom__column bottom__column--11">
        <div class="bottom__app-header">
          Мобильное приложение «Свои»
        </div>
        <mq-responsive target="small+" class="bottom__app-text">
          Специальные предложения для Вас от известных и популярных брендов
        </mq-responsive>
      </div>
      <div class="bottom__column bottom__column--12">
        <a href="" class="bottom__app-link">
          <img class="bottom__app-img bottom__apple-img"
            :src="require('@/assets/img/apple.svg')">
          <mq-responsive tag="span" target="small+">Доступно в AppStore</mq-responsive>
          <mq-responsive tag="span" target="mobile">Скачать для iOS</mq-responsive>
        </a>
      </div>
      <div class="bottom__column bottom__column--13">
        <a href="" class="bottom__app-link">
          <img class="bottom__app-img bottom__apple-img"
            :src="require('@/assets/img/android.svg')">
          <mq-responsive tag="span" target="small+">Доступно в Google Play</mq-responsive>
          <mq-responsive tag="span" target="mobile">Скачать для Android</mq-responsive>
        </a>
      </div>
    </div>
    <div class="bottom__row bottom__row--2">
      <div class="bottom__column bottom__column--21">
        <a
          class="bottom__tel"
          href="tel:8-800-775-15-98"
        >
          8 (800) 775-15-98
        </a>
        <div class="bottom__address">
          г. Москва, ул. Островная, 4
        </div>
        <div class="bottom__social-links">
          <a href="" class="bottom__social-link">
            <img :src="require('@/assets/img/youtube.svg')"
              class="bottom__social-img-youtube">
          </a>
          <a href="" class="bottom__social-link">
            <img class="bottom__social-img-vk"
              :src="require('@/assets/img/vk.svg')">
          </a>
          <a href="" class="bottom__social-link">
            <img class="bottom__social-img-tg"
              :src="require('@/assets/img/tg.svg')">
          </a>
          <a href="" class="bottom__social-link">
            <img class="bottom__social-img-ok"
              :src="require('@/assets/img/ok.svg')">
          </a>
        </div>
      </div>
      <div class="bottom__column bottom__column--22">
        <a href="" class="bottom__product-link">
          О нас
        </a>
        <a href="" class="bottom__product-link">
          Страховые продукты
        </a>
        <a href="" class="bottom__product-link">
          Партнеры
        </a>
        <a href="" class="bottom__product-link">
          Контакты
        </a>
      </div>
      <div class="bottom__column bottom__column--23">
        <div class="bottom__company-name">
          © Страховое акционерное общество “ВСК”, 2022
        </div>
        <img class="bottom__company-img" :src="require('@/assets/img/vskw.svg')">
      </div>
    </div>
    <div class="bottom__row bottom__row--3">
      <mq-responsive target="small+" class="bottom__column bottom__column--31">
        <img :src="require('@/assets/img/sign.svg')"
          class="bottom__sign-img">
      </mq-responsive>
      <div class="bottom__column bottom__column--32">
        <div class="bottom__text">
          ИНФОРМАЦИЮ, ПОДЛЕЖАЩУЮ РАСКРЫТИЮ В СООТВЕТСТВИИ С ТРЕБОВАНИЯМИ
          ФЕДЕРАЛЬНОГО ЗАКОНА «О РЫНКЕ ЦЕННЫХ БУМАГ» (ОТ 22.04.1996 N 39-ФЗ)
          И ПОЛОЖЕНИЯ О РАСКРЫТИИ ИНФОРМАЦИИ ЭМИТЕНТАМИ ЭМИССИОННЫХ ЦЕННЫХ БУМАГ
          (УТВ. БАНКОМ РОССИИ 30.12.2014 N 454-П), САО «ВСК» РАСКРЫВАЕТ НА
          СТРАНИЦЕ В СЕТИ ИНТЕРНЕТ ООО «ИНТЕРФАКС-ЦРКИ» – ИНФОРМАЦИОННОГО
          АГЕНТСТВА, АККРЕДИТОВАННОГО ЦБ РФ НА РАСКРЫТИЕ ИНФОРМАЦИИ, ПО АДРЕСУ:
          HTTP://WWW.E-DISCLOSURE.RU/PORTAL/COMPANY.ASPX?ID=1968
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {MqResponsive} from "vue3-mq"
export default {
  name: 'BottomTag',
  components: {
    MqResponsive,
  },
}
</script>

<style lang="scss">
.bottom {
  position: relative;
  background: #3478F6;
  padding: 2.14rem 11.29rem 3.86rem 11.29rem;
  color: #ffffff;
  @media (max-width: 600px){
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  }
  a{
    color: #ffffff;
  }
  &__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 600px){
    flex-direction: column;
    align-items: center;
    }
    &+&{
      margin-top: 2rem;
      @media (max-width: 600px){
      margin-top: 0;
      }
    }
    &--1 {
      @media (max-width: 600px) {
      align-items: flex-start;
      }
    }
    &--3 {
      display: flex;
      align-items: center;
    }
  }
  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      &--21 {
        position: absolute;
        right: 3rem;
        top: .7rem;
      }
      &--22 {
        flex-direction: row;
        justify-content: space-between;
        padding-top: 1.5rem;
        width: 100%;
      }
      &--23 {
        width: 100%;
      }
      &--13{
        margin-top: .6rem;
      }
    }
  }
  &__app-header {
    font-weight: 600;
    line-height: 96.9%;
    font-size: 1.86rem;
    margin-bottom: 0.43rem;
    @media (max-width: 600px){
    font-size: 0.86rem;
    max-width: 11.36rem;
    margin-bottom: .64rem;
    }
  }
  &__app-text {
    font-weight: 500;
    line-height: 122.9%;
    color: #DCDCDC;
    font-size: 1rem;
  }
  &__app-link {
    display: flex;
    align-items: center;
    background: #5D93F7;
    font-weight: 500;
    line-height: 122.9%;
    border-radius: 0.71rem;
    height: 3.71rem;
    font-size: 1rem;
    padding: 0 1.43rem;
    @media (max-width: 600px){
    justify-content: center;
    font-size: .57rem;
    padding: 0;
    width: 9.07rem;
    height: 1.43rem;
    border-radius: .29rem;
    }
  }
  &__app-img {
    margin-right: 0.7rem;
    @media (max-width: 600px){
    margin-right: 0.4rem;
    }
  }
  &__apple-img {
    width: 1.14rem;
    height: 1.37rem;
    @media (max-width: 600px){
    width: .53rem;
    height: .63rem;
    }
  }
  &__android-img {
    width: 1.14rem;
    height: 1.24rem;
    @media (max-width: 600px){
    width: .43rem;
    height: .46rem;
    }
  }
  &__tel {
    font-weight: 600;
    font-size: 2.36rem;
    line-height: 2.86rem;
    @media (max-width: 600px){
    font-size: 1.14rem;
    line-height: 1.43rem;
    margin-top: 1.64rem;
    }
  }
  &__address {
    font-weight: 500;
    font-size: 1.29rem;
    line-height: 1.57rem;
    @media (max-width: 600px){
    margin-top: .4rem;
    font-size: .57rem;
    line-height: .71rem;
    }
  }
  &__social-links {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    @media (max-width: 600px){
    margin-top: .7rem;
    margin-bottom: .5rem;
    }
  }
  &__social-link {
    &+&{
      margin-left: 1.14rem;
      @media (max-width: 600px){
      margin-left: .7rem;
      }
    }
  }
  &__social-img-youtube {
    width: 2.86rem;
    height: 2.86rem;
    @media (max-width: 600px){
    width: .93rem;
    height: 1rem;
    }
  }
  &__social-img-vk {
    width: 2.36rem;
    height: 2.36rem;
    @media (max-width: 600px){
    width: .79rem;
    height: .86rem;
    }
  }
  &__social-img-tg {
    width: 2.21rem;
    height: 1.86rem;
    @media (max-width: 600px){
    margin-top: .15rem;
    width: .89rem;
    height: .64rem;
    }
  }
  &__social-img-ok {
    width: 2.71rem;
    height: 2.71rem;
    @media (max-width: 600px){
    width: .92rem;
    height: .93rem;
    }
  }
  &__product-link {
    font-weight: 600;
    font-size: 1.29rem;
    line-height: 1.57rem;
    @media (max-width: 600px){
    font-size: .85rem;
    line-height: .71rem;
    }
    &+&{
      margin-top: 0.86rem;
      @media (max-width: 600px){
      margin-top: 0rem;
      }
    }
  }
  &__company-name {
    font-weight: 600;
    font-size: 1.31rem;
    line-height: 2.07rem;
    margin-bottom: 1.4rem;
    @media (max-width: 600px){
    font-weight: 500;
    font-size: .88rem;
    line-height: .86rem;
    margin-top: 1.4rem;
    text-align: center;
    margin-bottom: .7rem;
    }
  }
  &__company-img {
    width: 12.71rem;
    height: 3.43rem;
    @media (max-width: 600px){
    margin-top: .3rem;
    width: 7.43rem;
    height: 2rem;
    }
  }
  &__sign-img {
    width: 5.29rem;
    height: 5.29rem;
    margin-right: 2rem;
  }
  &__text {
    font-weight: 300;
    font-size: 0.71rem;
    line-height: 0.86rem;
    @media (max-width: 600px){
    font-weight: 500;
    font-size: 0.5rem;
    line-height: 0.6rem;
    margin-top: 1.3rem;
    }
  }
}
</style>
