<template>
  <label class="container">
    <slot></slot>
    <input type="checkbox" :checked="value" @change="updateInput">
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  name: 'CuCheckbox',
  props:{
    value: {
      type: Boolean,
      default: false,
    }
  },
  methods:{
    updateInput(){
      this.$emit('update:value', !this.value);
    },
  }
}
</script>

<style lang="scss">
 /* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 2.5rem;
  width: 2.5rem;
  background: #EAF0F8;
  border-radius: .58rem;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #E0E6EE;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  opacity: 1;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  display: block;
  opacity: 0;
  left: .86rem;
  top: .36rem;
  width: .79rem;
  height: 1.36rem;
  border: solid #3478F6;
  border-width: 0 .15rem .15rem 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: opacity .1s ease;
}
</style>
