<template>
  <form class="form__content" @submit.prevent="submitForm(!v$.$invalid)">
    <div class="form__row">
      <div class="p-float-label form__field form__field--flex--1"
        :class="{'form__field--error':v$.data.cost.$error}">
        <InputNumber id="cost"
          type="text"
          v-model="data.cost"
        />
        <label for="cost">Цена билета</label>
        <div v-if="v$.data.cost.$error"
          class="form__validate p-error">
          {{v$.data.cost.$errors[0].$message}}
        </div>
      </div>
    </div>
    <div class="form__row" v-for="(item, i) in there" :key="i"
      :class="{'form__row--bottom': there.length === (i + 1) || i}">
      <div class="p-float-label form__field form__field--flex--1"
        :class="{
          'form__field--error':
          v$.$dirty && v$.there.$each.$response.$errors[i].flight_there.length
        }">
        <PInputText id="flight_there" type="text" v-model="item.flight_there"
          placeholder="LH1"
        />
        <label for="flight_there">
          Номер рейса вылета <span v-if="there.length > 1">{{i+1}}</span>
        </label>
        <div v-if="v$.$dirty && v$.there.$each.$response.$errors[i].flight_there.length"
          class="form__validate p-error">
          {{v$.there.$each.$response.$errors[i].flight_there[0].$message}}
        </div>
      </div>
      <mq-responsive target="small+" class="p-float-label form__field form__field--flex--1"
        :class="{
          'form__field--error':
          v$.$dirty && v$.there.$each.$response.$errors[i].flight_there_datetime.length
        }">
        <PCalendar id="flight_there_datetime" v-model="item.flight_there_datetime"
          :showIcon="true" :showTime="true"
          :minDate="now"
        />
        <label for="flight_there_datetime">
          Время рейса вылета <span v-if="there.length > 1">{{i+1}}</span>
        </label>
        <div v-if="v$.$dirty && v$.there.$each.$response.$errors[i].flight_there_datetime.length"
          class="form__validate p-error">
          {{v$.there.$each.$response.$errors[i].flight_there_datetime[0].$message}}
        </div>
      </mq-responsive>
      <mq-responsive target="mobile" class="p-float-label form__field form__field--flex--1"
        :class="{
          'form__field--error':
          v$.$dirty && v$.there.$each.$response.$errors[i].flight_there_datetime.length}
        ">
        <PCalendar id="flight_there_datetime" v-model="item.flight_there_datetime"
          :showIcon="true" :showTime="true" :touchUI="true"
          :minDate="now"
        />
        <label for="flight_there_datetime">
          Время рейса вылета <span v-if="there.length > 1">{{i+1}}</span>
        </label>
        <div v-if="v$.$dirty && v$.there.$each.$response.$errors[i].flight_there_datetime.length"
          class="form__validate p-error">
          {{v$.there.$each.$response.$errors[i].flight_there_datetime[0].$message}}
        </div>
      </mq-responsive>
      <div class="form__toolbar">
        <div class="form__toolbar--left">
          <div class="form__toolbar-button"
            v-if="there.length === (i + 1)"
            @click="addConnecting('there')"
          >
            Добавить пересадку
          </div>
          <div class="form__toolbar-button"
            v-if="there.length === (i + 1) && (back.length === 0)"
            @click="addConnecting('back')">
            Добавить обратный рейс
          </div>
        </div>
        <div class="form__toolbar--right">
          <div class="form__toolbar-button" @click="deleteConnecting('there', i)" v-if="i">
            Удалить пересадку
          </div>
        </div>
      </div>
    </div>
    <div class="form__row form__row--bottom" v-for="(item, i) in back" :key="i"
      :set="vback = v$.back.$each.$response.$errors[i]">
      <div class="p-float-label form__field form__field--flex--1"
        :class="{
          'form__field--error':
          v$.$dirty && v$.back.$each.$response.$errors[i].flight_back.length}
        ">
        <PInputText id="flight_back" type="text" v-model="item.flight_back"
          placeholder="LH1"
        />
        <label for="flight_back">
          Номер обратного рейса <span v-if="back.length > 1">{{i+1}}</span>
        </label>
        <div v-if="v$.$dirty && v$.back.$each.$response.$errors[i].flight_back.length"
          class="form__validate p-error">
          {{v$.back.$each.$response.$errors[i].flight_back[0].$message}}
        </div>
      </div>
      <mq-responsive target="small+" class="p-float-label form__field form__field--flex--1"
        :class="{
          'form__field--error':
          v$.$dirty && v$.back.$each.$response.$errors[i].flight_back_datetime.length}
        ">
        <PCalendar id="flight_back_datetime" v-model="item.flight_back_datetime"
          :showIcon="true" :showTime="true"
          :minDate="now"
        />
        <label for="flight_back_datetime">
          Время прилёта обр. рейса <span v-if="back.length > 1">{{i+1}}</span>
        </label>
        <div v-if="v$.$dirty && v$.back.$each.$response.$errors[i].flight_back_datetime.length"
          class="form__validate p-error">
          {{v$.back.$each.$response.$errors[i].flight_back_datetime[0].$message}}
        </div>
      </mq-responsive>
      <mq-responsive target="mobile" class="p-float-label form__field form__field--flex--1"
        :class="{
          'form__field--error':
          v$.$dirty && v$.back.$each.$response.$errors[i].flight_back_datetime.length}
        ">
        <PCalendar id="flight_back_datetime" v-model="item.flight_back_datetime"
          :showIcon="true" :showTime="true" :touchUI="true"
          :minDate="now"
        />
        <label for="flight_back_datetime">
          Время прилёта обр. рейса <span v-if="back.length > 1">{{i+1}}</span>
        </label>
        <div v-if="v$.$dirty && v$.back.$each.$response.$errors[i].flight_back_datetime.length"
          class="form__validate p-error">
          {{v$.back.$each.$response.$errors[i].flight_back_datetime[0].$message}}
        </div>
      </mq-responsive>
      <div class="form__toolbar">
        <div class="form__toolbar--left">
          <div class="form__toolbar-button" @click="addConnecting('back')" v-if="back.length === (i + 1)">
            Добавить пересадку
          </div>
        </div>
        <div class="form__toolbar--right">
          <div class="form__toolbar-button" @click="deleteBack" v-if="!i">
            Удалить обратный рейс
          </div>
          <div class="form__toolbar-button" @click="deleteConnecting('back', i)" v-if="i">
            Удалить пересадку
          </div>
        </div>
      </div>
    </div>
    <div class="form__row form__row--error">
      <transition name="fade">
        <div class="form__error" v-if="preloader.error">
          {{preloader.error}}
        </div>
      </transition>
    </div>
    <div class="form__devider"/>
    <div class="form__footer">
      <cu-checkbox class="form__checkbox" v-model:value="confirm">
        <div class="form__checkbox-text" :class="{'form__checkbox-text--error':v$.confirm.$error}">
          Я принимаю условия страхования
          и передачи информации
        </div>
      </cu-checkbox>
      <PButton class="form__button button" type="submit" label="Далее"
        :loading="preloader.visible"/>
    </div>
  </form>
</template>

<script>
import CuCheckbox from '../components/CuCheckbox.vue'
import {MqResponsive} from "vue3-mq"
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
export default {
  name: 'FormStep2',
  components: {
    CuCheckbox,
    MqResponsive,
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      there: {
        $each: helpers.forEach({
          flight_there: {
            required: helpers.withMessage('Поле является обязательным', required),
            flightCheck: helpers.withMessage('Введите корректный номер рейса', this.flightCheck),
          },
          flight_there_datetime: {
            required: helpers.withMessage('Поле является обязательным', required),
            checkDateTime: helpers.withMessage('Введите корректную дату', this.checkDateTime),
          },
        })
      },
      back: {
        $each: helpers.forEach({
          flight_back: {
            required: helpers.withMessage('Поле является обязательным', required),
            flightCheck: helpers.withMessage('Введите корректный номер рейса', this.flightCheck),
          },
          flight_back_datetime: {
            required: helpers.withMessage('Поле является обязательным', required),
            checkDateTime: helpers.withMessage('Введите корректную дату', this.checkDateTime),
          },
        })
      },
      data: {
        cost: {
          required: helpers.withMessage('Поле является обязательным', required),
        },
      },
      confirm: {
        checkTrue: this.checkTrue,
      },
    }
  },
  props: {
    preloader: Object,
    formdata: Object,
  },
  data(){
    return{
      there: [
        {
          flight_there: '',
          flight_there_datetime: '',
        }
      ],
      back: [],
      data:{
        cost: null,
        connecting_flight: false,
        flight_there: '',
        flight_back: '',
        flight_there_datetime: '',
        flight_back_datetime: '',
      },
      confirm: false,
      now: new Date(),
      today: new Date((new Date).setHours(0, 0, 0, 0)),
    }
  },
  mounted(){
    this.data.cost = this.formdata.cost;
    this.data.connecting_flight = this.formdata.connecting_flight;
    this.data.flight_there = this.formdata.flight_there;
    this.data.flight_back = this.formdata.flight_back;
    this.data.flight_there_datetime = this.formdata.flight_there_datetime;
    this.data.flight_back_datetime = this.formdata.flight_back_datetime;
  },
  methods: {
    getISODateTime(date){
      const isoSplitT = date.toISOString().split('T');
      const isoSplitDots = isoSplitT[1].split(':');
      const timezoneOffset = date.getTimezoneOffset();
      const timezone = timezoneOffset == 0 ? '+00:00' : (
        ((timezoneOffset > 0) ? '-' : '+') +
        ((Math.abs(timezoneOffset) / 60) < 10 ? '0' : '') +
        String(Math.abs(timezoneOffset) / 60 - (Math.abs(timezoneOffset) / 60 % 1)) + ':' +
        ((Math.abs(timezoneOffset) / 60 % 1) * 60 == 0 ? '00' :
          String((Math.abs(timezoneOffset) / 60 % 1) * 60))
      )
      return isoSplitT[0] + ' ' + isoSplitDots[0] + ':' + isoSplitDots[1] + ' ' + timezone;
    },
    async submitForm(){
      const isFormValid = await this.v$.$validate()
      if (!isFormValid) {
        console.log('error');
        return;
      }
      this.data.flight_there = this.there.map((item) => {
        return item.flight_there;
      }).join(', ');
      this.data.flight_there_datetime = this.there.map((item) => {
        return this.getISODateTime(item.flight_there_datetime);
      }).join(', ');
      this.data.flight_back = this.back.map((item) => {
        return item.flight_back;
      }).join(', ');
      this.data.flight_back_datetime = this.back.map((item) => {
        return this.getISODateTime(item.flight_back_datetime);
      }).join(', ');
      if (this.there.length > 1 || this.back.length > 1) {
        this.data.connecting_flight = true;
      }
      console.log('success');
      console.log(this.data);
      this.$emit('next', this.data);
    },
    addConnecting(prop) {
      this[prop].push({
        ['flight_' + prop]: '',
        ['flight_' + prop + '_datetime']: '',
      });
    },
    deleteConnecting(prop, index) {
      this[prop].splice(index, 1);
    },
    deleteBack() {
      this.back = [];
    },
    flightCheck(value) {
      const regex = new RegExp('^[a-zA-Z0-9][a-zA-Z0-9][0-9]{1,4}$');
      return regex.test(value);
    },
    checkTrue(value){
      return value;
    },
    checkDateTime(value){
      return value >= this.now;
    },
  },
}
</script>

<style lang="scss">
.form {
  &__row--bottom {
    padding-bottom: 1.4rem;
    @media (max-width: 600px) {
    padding-bottom: 0;
    }
    .form__field--error {
    margin-bottom: 0.8rem;
    }
  }
  &__toolbar {
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: 0rem;
    display: flex;
    @media (max-width: 600px) {
    position: relative;
    flex-direction: column;
    }
    &--left {
      display: flex;
      @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-end;
      }
    }
    &--right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__toolbar-button {
    color: #3B82F6;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
    @media (max-width: 600px) {
    margin-top: .75rem;
    }
    & + & {
      margin-left: .5rem;
      @media (max-width: 600px) {
      margin-left: 0;
      }
    }
  }
  input::placeholder {
    color: white;
  }
  input:focus::placeholder {
    color: #6c757d;
  }
}
</style>
