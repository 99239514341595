<template>
  <div class="accordion" :class="{'accordion--open': visible}">
    <button class="accordion__button" @click="toogleAccordion">
      <slot name="header"></slot>
      <img class="accordion__arrow"
        :class="{'accordion__arrow--open': visible}"
        :src="require('@/assets/img/arrow.svg')">
   </button>
    <div class="accordion__panel" ref="panel" :style="{maxHeight: '0'}"
      :class="{'accordion__panel--open': visible}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CuAccordion',
  props:{
    header: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      visible: false,
    }
  },
  methods: {
    toogleAccordion(){
      if (!this.visible) {
        this.$refs.panel.style.maxHeight = this.$refs.panel.scrollHeight + 'px';
      } else {
        this.$refs.panel.style.maxHeight = 0;
      }
      this.visible = !this.visible;
    },
  }
}
</script>

<style lang="scss">
.accordion {
  border: 0.17rem solid #C9D2E0;
  border-radius: 0.71rem;
  transition: background-color .2s ease;
  &--open {
    background-color: #F3F8FF;
  }
  &__button {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    font-weight: 600;
    line-height: 122.9%;
    background: none;
    font-size: 1.57rem;
    padding: 1.86rem 2.21rem;
    @media (max-width: 600px){
    padding: 1.43rem 0.79rem;
    font-size: 0.86rem;
    }
  }
  &__arrow{
    margin-left: 2rem;
    transition: transform ease .2s;
    @media (max-width: 600px){
    width: 1.4rem;
    height: 0.7rem;
    }
    &--open{
      transform: rotate(180deg);
    }
  }
  &__panel {
    overflow: hidden;
    transition: max-height .2s ease;
    &--open{
      border-top: 0.17rem solid #C9D2E0;
    }
  }
  div {
    color: black;
  }
}
</style>
