import { createApp } from 'vue';
import App from './App.vue';
import { Vue3Mq } from "vue3-mq";

import PrimeVue from 'primevue/config';

import axios from 'axios'
import VueAxios from 'vue-axios'

import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputMask from 'primevue/inputmask';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button';

const app = createApp(App);

app.use(Vue3Mq, {
  preset: 'wordpress'
});

app.use(PrimeVue, {
  locale: {
    dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
    dayNamesMin: ["Вс","Пн","Вт","Ср","Чт","Пт","Сб"],
    monthNames: ["Январь","Февраль","Март","Апрель","Май","Июнь","Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь"],
    monthNamesShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн","Июл", "Авг", "Сен", "Окт", "Ноя", "Дек",],
    firstDayOfWeek: 1,
    dateFormat: 'dd.mm.yy',
  },
  ripple: true,
});

app.use(VueAxios, axios);

app.component('PInputText', InputText);
app.component('InputNumber', InputNumber);
app.component('InputMask', InputMask);
app.component('PCalendar', Calendar);
app.component('PDropdown', Dropdown);
app.component('SelectButton', SelectButton);
app.component('PButton', Button);

app.mount('#app');
